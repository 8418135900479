export default {
  meta: {
    imageData: {
      width: 1200,
      height: 800,
    },
  },
  ads: {
    // number of paragraphs before first banner in case of small template or lead position top
    first_position: 1,
    // chars number frequency after what ads should be injected into article body
    frequency: 2000,
    frequency_mobile: 900,
    min_chunk_size: 800,
  },
  commentsAds: {
    config: {
      freq: 6,
      countReplies: true,
    },
    placements: [
      {
        cssClass: 'col-324',
        adForm: [
          {
            type: 'tower',
            device: 'desktop',
            mid: 435609,
            mkw: [],
            mkv: {},
            lazyload: true,
            widthAtLeast: 768,
            cssClass: 'display-none display-lg-block ad-sticky',
          },
        ],
      },
      {
        cssClass: 'col-wide',
        adForm: [
          {
            type: 'content',
            device: 'desktop',
            mid: 451075,
            mkw: [],
            mkv: {},
            lazyload: true,
            widthAtLeast: 768,
            cssClass: 'display-none display-lg-block',
          },
          {
            type: 'mobile',
            device: 'mobile',
            mid: 497401,
            mkw: [],
            mkv: {},
            lazyload: true,
            widthAtMost: 767,
            cssClass: 'display-lg-none',
          },
        ],
      },
    ],
  },
  infoCover: {
    // Picture sizes are separated by template type: normal | small | full
    normal: {
      lg: {
        w: 1200,
        h: 711,
      },
    },
    small: {
      lg: {
        w: 450,
        h: 450,
      },
      sm: {
        w: 450,
        h: 678,
      },
    },
    full: {
      md: {
        w: 1600,
        h: 914,
      },
      sm: {
        w: 768,
        h: 914,
        r: '1:1',
      },
      xs: {
        w: 767,
        h: 914,
        r: '9:16',
      },
    },
  },
  leadPicture: {
    sourceWordsSkip: ['FOTO', 'ФОТО'],
  },
};
