import * as ArticleByIDRaw from '@root/modules/article/types/article.raw.type';
import * as ArticleByID from '@root/modules/article/types/article.type';
import * as ArticleBodyByIDRaw from '@root/modules/article/types/articleBody.raw.type';
import * as ArticleBodyByID from '@root/modules/article/types/articleBody.type';
import { CommentsArticleData } from '@root/modules/article/types/article';
import { Config } from '@root/modules/channel/types/channel';
import DefaultDataAdapter from '@root/common/utils/DefaultDataAdapter';
import defaultsConfig from '@root/config/defaults.config';

import { convertArrayToObject } from '@root/common/utils/helpers';
import articleConfig from '~/src/modules/article/config/article.config';
import cloneDeep from 'lodash/cloneDeep';
import striptags from 'striptags';

export class ArticleAdapter extends DefaultDataAdapter {
  private _article: ArticleByIDRaw.ArticleRaw | ArticleBodyByIDRaw.ArticleRaw;
  private _settings: Config['page']['article']['component'];

  constructor(originalData: ArticleByIDRaw.ArticleRaw | ArticleBodyByIDRaw.ArticleRaw, settings: Config['page']['article']['component']) {
    super();
    this._article = originalData;
    this._settings = settings;
  }

  private isWideFragment(fragment: ArticleByID.ContentBodyContent | ArticleBodyByID.ContentBodyContent) {
    if (fragment.type === 'embed' || fragment.type === 'media') {
      const { alignment = 'center', code = '', metadata = null } = fragment.attrs;
      // TODO: Remove when content-api returns valid data
      const isWide = (code as string).includes('width-screen') || metadata?.alignment === 'Full-width' || metadata?.alignment === 'fullWidth';

      if (alignment === 'screen' || alignment.toLowerCase() === 'fullwidth' || isWide) {
        return true;
      }
    }
    return false;
  }

  private getArticleGroupedBody(
    {
      articleBody,
      articleSettings,
    }: { articleBody: ArticleByID.Article['content']['body'] | ArticleBodyByID.Article['content']['body']; articleSettings: ArticleByID.Article['settings'] },
    settings: Config['page']['article']['component']
  ): ArticleByID.FragmentsGroup[] {
    const initFragmentsGroup: ArticleByID.FragmentsGroup = {
      items: [],
      options: {
        skipAds: false,
        addRelatedHeadlines: false,
        relatedPosition: 1,
      },
    };
    const { relatedArticles, info } = settings;
    const topSection = articleSettings.articleTemplate || 'default';
    const skipBodyFragments = info.leadPosition === 'top' || topSection === 'smallImage' ? 1 : 0;
    const relatedArticlesSettings = relatedArticles;

    const fragmentsSize = articleBody.content.length;

    if (!articleBody || fragmentsSize === 0) {
      return [];
    }

    const adsFrequency = articleConfig.ads.frequency;
    const group: ArticleByID.FragmentsGroup[] = [];

    let charCounter = 0;
    let mobileAdInjected = false;
    let addRelatedHeadlines = true;
    let fragments: (ArticleByID.ContentBodyContent | ArticleBodyByID.ContentBodyContent | string)[] = [];
    let fragmentsGroup = cloneDeep(initFragmentsGroup);

    for (let i = 0; i < fragmentsSize; i++) {
      if (skipBodyFragments > 0 && i < skipBodyFragments) {
        continue;
      }
      const isLast = i === fragmentsSize - 1;
      const fragment = articleBody.content[i];

      const text = striptags(fragment.html);
      let closeSubGroup = false;
      // TODO: Remove when content-api returns valid data
      if (this.isWideFragment(fragment)) {
        fragment.attrs.alignment = 'fullwidth';
        fragmentsGroup.options.skipAds = true;
      }
      // if fragment width is wide or viewport width close previous group and create new with single wide fragment without ads
      if (this.isWideFragment(fragment) && charCounter !== 0) {
        fragmentsGroup.items = fragments;
        group.push(fragmentsGroup);
        fragmentsGroup = cloneDeep(initFragmentsGroup);
        fragmentsGroup.options.skipAds = true;
        mobileAdInjected = false;
        charCounter = 0;
        fragments = [];
        closeSubGroup = true;
      }

      fragments.push(fragment);

      if (articleConfig.ads.frequency_mobile <= charCounter && !mobileAdInjected) {
        mobileAdInjected = true;
        fragments.push('mobileAd');
      }

      charCounter += text.length;
      // close subGroup if chunk size >= frequency size or fragment is wide/viewport width or last iteration
      if (charCounter > adsFrequency || closeSubGroup || isLast) {
        // if last sub-group size is smaller then minimum chunk size merge it with previous sub-group
        if (isLast && charCounter < articleConfig.ads.min_chunk_size && group.length > 1) {
          group[group.length - 1].items = [...group[group.length - 1].items, ...fragments];
        } else {
          fragmentsGroup.items = fragments;
          fragmentsGroup.options.addRelatedHeadlines = fragments.length > relatedArticlesSettings.minParagraphsLength && addRelatedHeadlines;

          // Calculate related headlines position in fragments group
          if (fragmentsGroup.options.addRelatedHeadlines) {
            fragmentsGroup.options.relatedPosition = relatedArticlesSettings.afterParagraphPosition;

            for (const [index, fragment] of fragments.entries()) {
              const isHTML = typeof fragment !== 'string' && !(fragment.type === 'sidebar' || fragment.type === 'pullout' || fragment.type === 'embed');
              if (isHTML && index >= fragmentsGroup.options.relatedPosition) {
                fragmentsGroup.options.relatedPosition = index;
                break;
              }
            }
          }
          // Remove last mobile ad injection from fragments array, because of upcoming content ad fallbacks to mobileAd also
          if (fragments.slice(-1)[0] === 'mobileAd') {
            fragments.pop();
          }

          group.push(fragmentsGroup);
          fragmentsGroup = cloneDeep(initFragmentsGroup);

          addRelatedHeadlines = false;
          mobileAdInjected = false;
          charCounter = 0;
          fragments = [];
          closeSubGroup = false;
        }
      }
    }

    return group;
  }

  private adaptArticleContentBodyContent(content: ArticleByIDRaw.ArticleContentBodyContentRaw[] | undefined): ArticleByID.ContentBodyContent[] {
    if (!content) {
      return [];
    }

    return content.map((item) => {
      const attrs = item.attrs;
      const alignment = attrs?.alignment || 'center';
      const metadata = attrs?.metadata;
      const metadataAlignment = metadata?.alignment || 'center';

      return {
        type: item.type,
        html: item.html,
        attrs: {
          ...attrs,
          alignment,
          metadata: {
            ...metadata,
            alignment: metadataAlignment,
          },
        },
      };
    });
  }

  private adaptArticleContent(
    content: ArticleByIDRaw.ArticleRaw['content']
  ): Pick<ArticleByID.Article['content'], 'summary' | 'paywall' | 'body' | 'title' | 'subtitle' | 'lead' | 'leadElement'> {
    return {
      summary: {
        html: content.summary?.html || '',
      },
      paywall: {
        enabled: content.paywall?.enabled || false,
        access: content.paywall?.access || false,
        price: content.paywall?.price || 0,
        attributes: content.paywall?.attributes || null,
      },
      body: {
        length: content.body?.length || 0,
        content: this.adaptArticleContentBodyContent(content.body?.content),
      },
      title: {
        text: content.title?.text || '',
        html: content.title?.html || '',
      },
      subtitle: {
        text: content.subtitle?.text || '',
      },
      lead: {
        text: content.lead?.text || '',
        html: content.lead?.html || '',
        content: content.lead?.content || [],
      },
      leadElement: {
        type: content.leadElement?.type || '',
        content: content.leadElement?.content || [],
      },
    };
  }

  // FIXME: Separate ArticleByID and ArticleBodyByID adapters and remove undefined from primaryCategory
  protected adaptPrimaryCategory(primaryCategory: ArticleByIDRaw.ArticleRaw['primaryCategory'] | undefined): ArticleByID.Article['primaryCategory'] {
    return {
      id: primaryCategory?.id || 0,
      name: primaryCategory?.name || '',
      slug: primaryCategory?.slug || '',
      parentCategory: {
        id: primaryCategory?.parentCategory?.id || null,
        slug: primaryCategory?.parentCategory?.slug || '',
        name: primaryCategory?.parentCategory?.name || '',
      },
      channel: {
        domain: primaryCategory?.channel.domain || '',
        name: primaryCategory?.channel.name || '',
        language: primaryCategory?.channel.language || '',
      },
    };
  }

  private article(): ArticleByID.Article | ArticleBodyByID.Article {
    // FIXME: Separate ArticleByID and ArticleBodyByID adapters and remove @ts-ignore
    // @ts-ignore
    const settings = convertArrayToObject<ArticleByID.Article['settings']>(this._article.settings, defaultsConfig.article.settings);
    // FIXME: Separate ArticleByID and ArticleBodyByID adapters and remove @ts-ignore
    // @ts-ignore
    const comments = convertArrayToObject<ArticleByID.Article['comments']>(this._article.comments, defaultsConfig.article.comments);
    // FIXME: Separate ArticleByID and ArticleBodyByID adapters and remove @ts-ignore
    // @ts-ignore
    const banners = convertArrayToObject<ArticleByID.Article['banners']>(this._article.banners, defaultsConfig.article.banners);
    const voiceSettings = convertArrayToObject<ArticleByID.Article['voiceSettings']>(this._article.voiceSettings, defaultsConfig.article.voiceSettings);
    // FIXME: Separate ArticleByID and ArticleBodyByID adapters and remove @ts-ignore
    // @ts-ignore
    const externalTracker = convertArrayToObject<ArticleByID.Article['externalTracker']>(this._article.externalTracker);
    // FIXME: Separate ArticleByID and ArticleBodyByID adapters and remove @ts-ignore
    // @ts-ignore
    const seoFields = convertArrayToObject<ArticleByID.Article['seoFields']>(this._article.seoFields, defaultsConfig.article.seoFields);
    // FIXME: Separate ArticleByID and ArticleBodyByID adapters and remove @ts-ignore
    // @ts-ignore
    const contentValues = convertArrayToObject<ArticleByID.Article['contentValues']>(this._article.contentValues, defaultsConfig.article.contentValues);

    // FIXME: Separate ArticleByID and ArticleBodyByID adapters and remove @ts-ignore
    // @ts-ignore
    const articleContent = this.adaptArticleContent(this._article.content);
    const groupedBody = this.getArticleGroupedBody({ articleBody: articleContent.body, articleSettings: settings }, this._settings);

    // FIXME: Separate ArticleByID and ArticleBodyByID adapters and remove @ts-ignore
    // @ts-ignore
    return {
      ...this._article,
      settings,
      comments,
      banners,
      voiceSettings,
      externalTracker,
      seoFields,
      contentValues,
      // FIXME: Separate ArticleByID and ArticleBodyByID adapters and remove @ts-ignore
      // @ts-ignore
      primaryCategory: this.adaptPrimaryCategory(this._article.primaryCategory),
      content: {
        ...articleContent,
        groupedBody,
      },
    };
  }

  public adapt() {
    const article = this.article();
    return article;
  }
}

export class ArticleCommentsAdapter extends DefaultDataAdapter {
  private _article: CommentsArticleData;

  constructor(originalData: CommentsArticleData) {
    super();
    this._article = originalData;
  }

  private article() {
    const article = this._article;

    article.comments = convertArrayToObject<CommentsArticleData['comments']>(article.comments, defaultsConfig.article.comments);
    article.content = this.content(article.content).pick(['title', 'lead', 'paywall']);
    article.contentValues = convertArrayToObject<CommentsArticleData['contentValues']>(article.contentValues, defaultsConfig.article.contentValues);
    article.primaryCategory = this.primaryCategory(article.primaryCategory).all();
    article.authors = article.authors || defaultsConfig.article.authors;

    return article;
  }

  public adapt() {
    const article = this.article();
    return article;
  }
}
