import { GetChannelConfigFunction } from '@root/libs/init-channel/types';
import { Article } from '@root/modules/article/types/article.type';
import { CommentsArticleData } from '@root/modules/article/types/article';
import { Route } from 'vue-router';

export default function getArticleAdsSettings({
  $channelConfig,
  $route,
  article,
}: {
  $channelConfig: GetChannelConfigFunction;
  $route: Route;
  article: Article | CommentsArticleData;
}) {
  const { primaryCategory } = article;
  const { component } = $channelConfig('page').category;
  const { id } = $channelConfig('settings');

  // Create keyValues data
  const parentCategoryKey = article.primaryCategory.parentCategory ? `_${article.primaryCategory.parentCategory?.slug}` : '';

  const keyValue: Record<string, unknown> = {
    article_id: article.id,
    category: `${id}${parentCategoryKey}_${article.primaryCategory.slug}`,
  };

  if ($route.name === 'articleComments') {
    keyValue.chn_spec = 'Comments_section';
  }

  // Create keywords data
  const keywords = [
    `${id}_article`,
    `article_${article.id}`,
    `category_${article.primaryCategory.id}`,
    `${id}_${article.id}`,
    `${id}_${article.primaryCategory.slug}`,
    `template_${article.settings.articleTemplate}`,
  ];

  if (article.tags.items) {
    article.tags.items.forEach((tag) => {
      keywords.push(`tag-${tag.slug}`);
    });
  }

  // Get page placements
  const adsConfig = component?.adForm?.filter((ad) => ad.categories.includes(String(primaryCategory.id))) || [];
  const placements = adsConfig[0]?.placements ? adsConfig[0]?.placements : {};

  // Get enabled ads
  const enableAds = adsConfig[0]?.subscriberShow ? adsConfig[0]?.subscriberShow : {};

  return {
    mkw: keywords,
    mkv: keyValue,
    placements,
    enableAds,
    uniqueKey: [`${id}_${article.primaryCategory.slug}`],
  };
}
