import { PrimaryCategory } from '@root/common/types/fragment';
import pick from 'lodash/pick';

// Todo: better types
type UniversalContent = {
  summary?: Record<string, any>;
  paywall?: Record<string, any>;
  body?: Record<string, any>;
  title?: Record<string, any>;
  subtitle?: Record<string, any>;
  lead?: Record<string, any>;
  leadElement?: Record<string, any>;
  groupedBody?: Record<string, any>[];
};

type UniversalPrimaryCategory = PrimaryCategory & {
  parentCategory: {
    id: number;
    slug: string;
    name: string;
  };
};

export default class DefaultDataAdapter {
  private _data: unknown;

  protected all<G extends UniversalContent | UniversalPrimaryCategory>() {
    return this._data as G;
  }

  // NB! picked types are not dynamic, return types are inherited from the the data type
  protected pick<G extends UniversalContent | UniversalPrimaryCategory>(desiredKeys: string | string[]) {
    return pick(this._data, desiredKeys) as G;
  }

  protected content<G extends UniversalContent>(content: G) {
    const adaptedContent = {
      summary: {
        html: content.summary?.html || '',
      },
      paywall: {
        enabled: content.paywall?.enabled || false,
        access: content.paywall?.access || false,
        price: content.paywall?.price || 0,
        teaser: {
          lead: {
            html: content.paywall?.teaser?.lead?.html || '',
          },
          body: {
            html: content.paywall?.teaser?.body?.html || '',
          },
        },
        attributes: content.paywall?.attributes || null,
      },
      body: {
        length: content.body?.length || 0,
        content: content.body?.content || [],
      },
      title: {
        text: content.title?.text || '',
        html: content.title?.html || '',
      },
      subtitle: {
        text: content.subtitle?.text || '',
      },
      lead: {
        text: content.lead?.text || '',
        html: content.lead?.html || '',
        content: content.lead?.content || [],
      },
      leadElement: {
        type: content.leadElement?.type || '',
        content: content.leadElement?.content || [],
      },
      groupedBody: content.groupedBody || [],
    };

    this._data = adaptedContent;

    return this;
  }

  protected primaryCategory<G extends UniversalPrimaryCategory>(primaryCategory: G) {
    const adaptedCategory = {
      id: primaryCategory?.id || null,
      name: primaryCategory?.name || '',
      slug: primaryCategory?.slug || '',
      parentCategory: {
        id: primaryCategory?.parentCategory?.id || null,
        slug: primaryCategory?.parentCategory?.slug || '',
        name: primaryCategory?.parentCategory?.name || '',
      },
      channel: {
        domain: primaryCategory?.channel?.domain || '',
        name: primaryCategory?.channel?.name || '',
        language: primaryCategory?.channel?.language || '',
      },
    };

    this._data = adaptedCategory;

    return this;
  }
}
