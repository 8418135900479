import { Article } from '@root/modules/article/types/article.type';
import { CommentsArticleData } from '@root/modules/article/types/article';

// Replace nCMS old selectors with new ones
export default function getArticleCustomStyles(article: Article | CommentsArticleData) {
  if (!article.settings.customCss) {
    return false;
  }

  let styles = '';
  let find = /.article /g;
  styles = article.settings.customCss.replace(new RegExp(find), `#article-${article.id} `);

  find = /.article(__|-)body /g;
  styles = styles.replace(new RegExp(find), '');
  find = /.article(__|-)title /g;
  styles = styles.replace(new RegExp(find), '.article-info__title ');
  find = /.article__pullout /g;
  styles = styles.replace(new RegExp(find), '.fragment-pullout ');

  return styles;
}
