const cropperConfig = {
  width: 'w',
  height: 'h',
  w: 'w',
  h: 'h',
  r: 'r',
  fx: 'fx',
  fy: 'fy',
  cx: 'cx',
  cy: 'cy',
  cw: 'cw',
  ch: 'ch',
  noup: 'noup',
  rotate: 'rotate',
  flip: 'flip',
};

function cropperDataToQueryString(cropperData: Record<string, unknown>) {
  const cropperDataParams = [];

  for (const [key, value] of Object.entries(cropperData)) {
    if (value === undefined || value === null) {
      continue;
    }

    if (typeof value === 'string' && value.toLowerCase() === 'original') {
      continue;
    }

    const mappedKey = cropperConfig[<keyof typeof cropperConfig>key];

    if (mappedKey) {
      cropperDataParams.push(`${mappedKey}=${value}`);
    }
  }

  return cropperDataParams.length > 0 ? `&${cropperDataParams.join('&')}` : '';
}

function getCustomCropsPerRatio(customCrops?: { ratio: string; ch: number; cw: number; cx: number; cy: number }[]) {
  const defaultCustomCropsPerRatio = {
    '16:9': '',
    '1:1': '',
  };

  if (customCrops && customCrops.length > 0) {
    const customCropsModified = customCrops.reduce(
      (cropsObject, customCrops) => {
        const ratio = customCrops.ratio as keyof typeof cropsObject;

        if (ratio && ratio in cropsObject) {
          cropsObject[ratio] = cropperDataToQueryString(customCrops);
        }

        return cropsObject;
      },
      { ...defaultCustomCropsPerRatio }
    );

    return customCropsModified;
  }

  return defaultCustomCropsPerRatio;
}

function getFocuspointQueryString(focuspoint?: { x?: number; y?: number }) {
  let focusPointString = '';

  if (focuspoint?.x !== undefined && focuspoint?.x !== null) {
    focusPointString = `${focusPointString}&fx=${focuspoint?.x}`;
  }

  if (focuspoint?.y !== undefined && focuspoint?.y !== null) {
    focusPointString = `${focusPointString}&fy=${focuspoint?.y}`;
  }

  return focusPointString;
}

export { getCustomCropsPerRatio, cropperDataToQueryString, cropperConfig, getFocuspointQueryString };
